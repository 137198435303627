<template>
  <div id="Contacts">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <!-- *** Filtre par date a long terme ***  
            <v-col cols="4" md="4" style="margin-top: 10px">
              <hotel-date-picker
                style="opacity: 90%; z-index: 2"
                :halfDay="true"
                :displayClearButton="true"
                :i18n="frFR"
                :positionRight="false"
                :minNights="1"
                :format="dateFormat"
                :showYear="true"
                :showCloseButton="false"
                :startDate="new Date(new Date().getFullYear(), 0, 1)"
                :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                @check-in-changed="checkInChanged($event)"
                @check-out-changed="checkOutChanged($event)"
                @clear-selection="clear($event)"
              />
            </v-col>
             -->
            <v-card>
              <v-card-title> Contacts </v-card-title>
              <v-data-table
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, 30],
                }"
                :headers="headers"
                :items="contacts"
                :search="search"
                :server-items-length="totalContactsCount"
                :options.sync="options"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:item.name="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.name }}</div
                  >

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.name }}</div
                  >
                </template>

                <template v-slot:item.surname="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.surname }}</div
                  >

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.surname }}</div
                  >
                </template>
                <template v-slot:item.email="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.email }}</div
                  >

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.email }}</div
                  >
                </template>
                <template v-slot:item.phoneNumber="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.phoneNumber }}</div
                  >

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.phoneNumber }}</div
                  >
                </template>
                <template v-slot:item.date="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.date }}</div
                  >

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.date }}</div
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
//import nodesFieldName from "../../utils/nodesFieldName"; *** Filtre par date a long terme ***
import FirebaseDB from "../../utils/firebaseDB";
import FunctionsUtils from "./../../utils/functions";
import moment from "moment";
//import HotelDatePicker from "vue-hotel-datepicker2"; *** Filtre par date a long terme ***
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";

export default {
  name: "Contacts",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    // HotelDatePicker,
  },
  data() {
    return {
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date d'envoi du contact",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],

        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      // fromDate: new Date("2021-01-01"),
      //toDate: new Date(Date.now() + 3600 * 1000 * 24),

      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],

      menu: false,
      modalTo: false,
      modalFrom: false,
      contacts: [],
      totalContacts: [],
      search: "",
      options: {},
      name: null,
      surname: null,
      phoneNumber: null,
      email: null,
      totalContactsCount: 0,
      loading: true,
      lastVisibleContacts: null,
      userId: null,
      date: null,
      dates: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      from: null,
      to: null,
      read: false,
    };
  },

  async beforeRouteEnter(to, from, next) {
    next((vm) => (vm.loading = false));
  },

  methods: {
    getColor(read) {
      if (read == false) return "red";
    },
    /*  *** Filtre par date a long terme *** 
    clear() {
      this.fromDate = new Date("2021-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.totalContacts = [];
      this.contacts = [];
      this.lastVisibleContacts = null;
    },
    checkInChanged(newDate) {
      this.totalContacts = [];
      this.contacts = [];
      this.lastVisibleContacts = null;
      this.fromDate = newDate;
      this.loadContacts();
    },
    checkOutChanged(newDate) {
      this.totalContacts = [];
      this.contacts = [];
      this.lastVisibleContacts = null;
      this.toDate = newDate;
      this.loadContacts();
    }, */
    async loadContacts() {
      this.loading = true;
      /* *** Filtre par date a long terme *** 
      if (!this.fromDate && !this.toDate) {
        this.fromDate = new Date("2021-01-01");
        this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      }
*/
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = db.collection(NodesName.CONTACTS);
      //  .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
      //  .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);


      var generalStats = await FirebaseDB.getGeneralStats();
      generalStats.forEach((stat) => {
        this.totalContactsCount = stat.data().number_total_contacts;
      });

      if (this.totalContacts.length < this.totalContactsCount) {
        var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
          query,
          this.lastVisibleContacts,
          itemsPerPage,
          this.totalContactsCount
        );

        let i = 0;
        querySnapshot.forEach((contact) => {
          if (Object.entries(contact.data()).length !== 0) {
            const found = this.totalContacts.some(
              (el) => el.contact_id === contact.uniqueId
            );
            if (!found) {
              this.totalContacts.push({
                name: contact.data().name,
                surname: contact.data().surname,
                contact_id: contact.data().uniqueId,
                email: contact.data().email,
                phoneNumber: contact.data().phoneNumber,
                read: contact.data().read,
                date: moment(
                  new Date(contact.data().creationDate.seconds * 1000)
                ).format("L HH:mm:ss"),
              });
              setTimeout(() => {
                db.collection(NodesName.CONTACTS)
                  .doc(contact.data().uniqueId)
                  .update({
                    read: true,
                  });
              }, 15500);
              i++;

              if (querySnapshot.size == i) {
                this.lastVisibleContacts = contact;
              }
            }
          }
        });
      }

      this.contacts = FunctionsUtils.customSortDataTable(
        this.totalContacts,
        sortBy,
        sortDesc
      );

      this.contacts = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalContacts,
        itemsPerPage,
        page,
        this.totalContactsCount
      );

      this.loading = false;
    },
  },
  destroyed() {
    db.collection(NodesName.CONTACTS)
      .where("read", "==", false)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size >= 1) {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              read: true,
            });
          });
        }
      });
    localStorage.setItem("NbrContacts", 0);
  },

  computed: {
    /* *** Filtre par date a long terme ***
    dateFormat() {
      return "MM/DD/YYYY";
    }, */
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },

    headers() {
      return [
        { text: "Nom", value: "name" },
        { text: "Prénom", value: "surname" },
        { text: "Email", value: "email" },
        { text: "Numéro de téléphone", value: "phoneNumber" },
        { text: "Date d'envoi du contact", value: "date" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.loadContacts();
      },
      deep: true,
    },
  },
};
</script>
