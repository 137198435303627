var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Contacts"}},[_c('v-container',{staticClass:"lighten-5"},[_c('v-container',[_c('dashboard-core-app-bar')],1),_c('v-row',{staticStyle:{"margin-top":"20px"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":">"}}),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"pa-3",attrs:{"outlined":"","tile":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Contacts ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
                'items-per-page-options': [5, 10, 15, 30],
              },"headers":_vm.headers,"items":_vm.contacts,"search":_vm.search,"server-items-length":_vm.totalContactsCount,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [(item.read == true)?_c('div',{attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.name))]):_vm._e(),(item.read == false)?_c('div',{staticClass:"font-weight-black",attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.name))]):_vm._e()]}},{key:"item.surname",fn:function(ref){
              var item = ref.item;
return [(item.read == true)?_c('div',{attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.surname))]):_vm._e(),(item.read == false)?_c('div',{staticClass:"font-weight-black",attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.surname))]):_vm._e()]}},{key:"item.email",fn:function(ref){
              var item = ref.item;
return [(item.read == true)?_c('div',{attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.email))]):_vm._e(),(item.read == false)?_c('div',{staticClass:"font-weight-black",attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.email))]):_vm._e()]}},{key:"item.phoneNumber",fn:function(ref){
              var item = ref.item;
return [(item.read == true)?_c('div',{attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.phoneNumber))]):_vm._e(),(item.read == false)?_c('div',{staticClass:"font-weight-black",attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.phoneNumber))]):_vm._e()]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [(item.read == true)?_c('div',{attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.date))]):_vm._e(),(item.read == false)?_c('div',{staticClass:"font-weight-black",attrs:{"shaped":"","single-line":"","tile":""}},[_vm._v(" "+_vm._s(item.date))]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }